.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: white;

    height: 100vh;
    width: 100%;
    background-color: var(--tertiaryColor);
}

.hero .box {
    border: 1px solid white;
    padding: 3rem;
    padding-bottom: 5rem;

    margin: 2rem;
}

.scroll-down {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.arrow {
    position: relative;
}

.scroll-down a, a:visited {
    color: white;
    text-decoration: none;
}

.scroll-down:hover .arrow {
    animation-name: point;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes point {
    0% { top: 0; }
    50% { top: 10px; }
    100% { top: 0; }
}

@media only screen and (max-width: 600px) {
    .scroll-down {
        display: none;
    }
}