.flex-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    font-size: 1.2rem;
    max-width: 1200px;
    padding: 1rem;
    margin: 0 auto;
}

.project-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: 0;
    transition: 0.5s;
    color: black;
    background-color: white;
    min-height: 300px;
    height: auto;
    width: 332px;
    max-width: 600px;
    padding: 1rem;
    margin: 1.5rem 1rem;
    border: none;
    border-radius: 40px;
    box-shadow: rgba(50, 50, 50, 0.1) 3px 8px 10px 5px;
}

.project-card > img {
    height: 20vh;
    width: 20vh;
}

.project-card > h3 {
    color: var(--tertiaryColor);
    align-self: center;
}

.project-card:hover {
    top: -15px;
}
