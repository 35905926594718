.primary-button {
    display: flex;
    align-self: center;
    justify-content: center;
    
    min-height: 5vh;
    height: auto;
    width: 10rem;
    padding: 1rem 1.5rem;
    margin: 01rem;

    color: white;
    background-color: var(--secondaryColor);

    border: none;
    border-radius: 1.5rem;
    box-shadow: rgba(50, 50, 50, 0.1) 3px 8px 10px 5px;
}

.primary-button:hover {
    cursor: pointer;
}

.primary-button:active {
    transform: scale(0.9);
}

.primary-link {
    display: flex;
    align-self: center;
    justify-content: center;

    text-decoration: none;
}
