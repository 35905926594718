.secondary-button {
    display: flex;
    align-self: center;
    justify-content: center;

    padding: 1rem 1.5rem;
    margin: 0.5rem;

    color: var(--secondaryColor);
    background-color: white;

    border: 1px solid var(--secondaryColor);
    border-radius: 1.5rem;
    box-shadow: rgba(50, 50, 50, 0.1) 3px 8px 10px 5px;
}

.secondary-button:hover {
    cursor: pointer;
}

.secondary-button:active {
    transform: scale(0.9);
}

.secondary-link {
    display: flex;
    align-self: center;
    justify-content: center;

    text-decoration: none;
}

