.experience {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5vh;
    margin: 5vh;
}

.experience > hgroup {
    padding: 0;
    margin: 1rem;
}

.experience > hgroup > h2, p {
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 610px) {
    .experience {
        flex-direction: column;

        padding: 0;
        margin: 5vh 0;
    }
}