.about {
    display: flex;
    flex-direction: row;
    justify-content: center;

    min-height: 30vh;
    padding: 10vh;
    margin: 5vh;
}

.about > img {
    height: 250px;
    width: 250px;

    border: none;
    border-radius: 100%;
    box-shadow: rgba(50, 50, 50, 0.1) 3px 8px 10px 5px;
}

.about > hgroup {
    padding: 2rem;
    max-width: 50vw;
}

hr {
    width: 60%;
}

.social-icon {
    font-size: 2rem;
    padding: 0.5rem;
}

a .social-icon {
    color: var(--tertiaryColor);
}

button {
    display: flex;
    border: none;
    height: 2rem;
    margin: 0 auto;
    font-size: 1.2rem;
    color: var(--secondaryColor);
    background-color: white;
}

button:hover {
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .about {
        flex-direction: column;
        align-items: center;
    }

    .about > img{
        align-self: center;
    }
}

@media screen and (max-width: 610px) {
    .about {
        flex-direction: column;

        padding: 0;
        margin: 5vh;
    }
}