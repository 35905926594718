footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    color: white;

    background-color: var(--tertiaryColor);
    height: 8vh;
    width: 100%;
}