.tools {
    text-align: center;

    padding: 5vh;
    margin: 5vh;
}

.tools > hgroup {
    padding: 0;
    margin: 1rem;
}

.tools > hgroup > h2, p {
    padding: 0;
    margin: 0;
}

.tools-span {
    color: #777;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    padding: 0.5rem;
    margin: 1.5rem 2.6rem;
}

.tool-icon {
     font-size: 3rem;
}

@media screen and (max-width: 610px) {
    .tools {
        padding: 0;
        margin: 5vh 0;
    }
}