.contact {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    padding: 5vh;
    margin: 5vh 1rem;
}

.contact > hgroup {
    padding: 0;
    margin: 1rem;
}

.contact > hgroup > h2, p {
    padding: 0;
    margin: 0;
}

.contact label {
    padding: 0.5rem;
}

.contact input, textarea {
    font-size: 1rem;

    padding: 1rem 2rem;
    margin: 1rem 1.5rem;

    border: none;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 50, 0.1) 3px 8px 10px 5px;
}

.contact textarea {
    min-height: 20vh;

    width: 60vw;
    max-width: 600px
}

.submit-button {
    color: white;
    font-size: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1.5rem 2rem;
    margin: 1rem 1.5rem;

    border: none;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 50, 0.1) 3px 8px 10px 5px;
    background-color: var(--tertiaryColor);
}

.submit-button:hover {
    cursor: pointer;
}

.submit-button:active {
    transform: scale(0.9);
}

.alt-contact {
    margin: 1rem;
}

.alt-contact > a, 
.alt-contact > a:visited {
    color: var(--tertiaryColor);
    text-decoration: none;
}

@media screen and (max-width: 610px) {
    .contact {
        flex-direction: column;

        padding: 0;
        margin: 5vh 2rem;
    }
}
