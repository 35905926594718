.banner {
    z-index: 3;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 100vh;
    height: 100vh;
    width: 250px;
}

.banner > h3 {
    color: var(--tertiaryColor);
}

.banner > ul {
    list-style-type: none;
}

.banner > ul > li {
    display: flex;
    justify-content: flex-start;
    
    padding: 1rem;
    text-decoration: none;
}

.banner > ul > li > a {
    color: var(--tertiaryColor);
    text-decoration: none;
    display: flex;
    letter-spacing: 2px;
}

.banner > ul > li > a:visited {
    color: var(--tertiaryColor);
}

.banner-mobile {
    display: none;
}

@media only screen and (max-width: 1650px) {
    .banner {
        display: none;
        background-color: white;
    }

    .banner-mobile {
        display: block;
        position: absolute;
        bottom: 2rem;
        margin: 2rem;
    }

    .banner-mobile > ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        list-style-type: none;
    }

    .banner-mobile  > ul > li {
        padding: 1rem 1.5rem;
    }

    .banner-mobile  > ul > li > a {
        color: white;
        text-decoration: none;
    }

    .banner-mobile  > ul > li > a:visited {
        color: white;
        text-decoration: none;
    }
}

@media only screen and (max-width: 600px) {

    .banner-mobile {
        display: none;
    }
}