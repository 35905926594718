* {
  box-sizing: border-box;
}

html, body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

:root {
  --primaryColor: #fefae0;
  --secondaryColor: #606c38;
  --tertiaryColor: #283618;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.about,
.tools,
.experience,
.projects {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
