.flex-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    font-size: 1.2rem;

    max-width: 1200px;
    padding: 1rem;
    margin: 0 auto;
}

.exp-card {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    top: 0;
    transition: 0.5s;

    color: black;
    background-color: white;

    height: auto;
    width: 332px;

    padding: 1rem;
    margin: 1.5rem 1rem;

    border: none;
    border-radius: 40px;
    box-shadow: rgba(50, 50, 50, 0.1) 3px 8px 10px 5px;
}

.exp-card > h3 {
    color: var(--tertiaryColor);
    align-self: center;
}

.exp-card > span {
    align-self: center;
    padding: 0;
    margin: 0;

    color: var(--secondaryColor);
}

.exp-card > p:nth-child(3n+0) {
    font-weight: 700;
    margin-bottom: 0.2rem;
}

.exp-card > p:nth-child(3n+1) {
    font-style: italic;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;

    color: #777;
}

.exp-card:hover {
    top: -15px;
}
